import React from "react";
import "./StaffPage.css";

function StaffPage() {
  return (
    <div className="staff-page">
      <h1>Our Staff:</h1>
      {/* ADD MORE CONTENT */}
    </div>
  );
}

export default StaffPage;
